export const onlyDecimalNumbers = (e: any, allowDecimal = 2) => {
  const key = e.key;
  const keyCode = e.keyCode || e.which;

  // Allow numeric characters (0-9), the backspace key, and a single dot for decimal point
  if (keyCode === 8) {
    return;
  }
  if (!/^[0-9.]$/.test(key)) {
    e.preventDefault();
  }

  // Allow only one dot for decimal point
  if (key === "." && e.target.value.includes(".")) {
    e.preventDefault();
  }

  // Allow up to n decimal places, excluding the dot
  const value = e.target.value;
  const hasDot = value.includes(".");
  const decimalPart = hasDot ? value.split(".")[1] || "" : "";
  if (hasDot && decimalPart.length >= allowDecimal) {
    e.preventDefault();
  }
};
