
import Vue from "vue";
import ClipperModal from "@/components/ClipperModal.vue";
import ImageUploadModal from "@/components/Modals/ImageUploadModal.vue";
import MentorcastPhotoGalleryModal from "@/components/Modals/MentorcastPhotoGalleryModal.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { onlyDecimalNumbers } from "@/helpers/utils";

export default Vue.extend({
  name: "CourseForm",
  components: {
    ClipperModal,
    ImageUploadModal,
    MentorcastPhotoGalleryModal
  },
  props: {
    isPublished: Boolean,
    isCreated: Boolean,
    create: Boolean,
    platformCurrency: String,
    courseData: Object,
    courseImgUrl: String,
    languages: Array,
    platforms: Array,
    defaultPlatform: Number,
    audiences: Array,
    isLicence: Boolean,
    settings: Object,
    maxSeats: String,
    skipLicense: String,
    skipOverview: Boolean
  },
  data() {
    return {
      errors: {} as any,
      url: "",
      data: this.courseData as any,
      submitBtnLoading: false,
      newCategory: "",
      maxTitleChars: 100,
      maxHostURLChars: 350,
      maxDescChars: 450,
      categoryError: "",
      dateTime: "",
      showPassword: false,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      certificateOptions: [
        {
          id: 1,
          name: (this as any).$t("courseHero.certificateOptionsYes")
        },
        {
          id: 0,
          name: (this as any).$t("courseHero.certificateOptionsNo")
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  watch: {
    newCategory() {
      this.validateCategory();
    }
  },
  created(): void {
    this.countdown();
    this.setData();
  },
  methods: {
    onlyDecimalNumbers,
    onlyNumber(event: any) {
      const ch = String.fromCharCode(event.which);
      if (!/[0-9]/.test(ch)) {
        event.preventDefault();
      }
    },
    setData() {
      if (this.isPublished || this.isCreated) {
        this.data = { ...this.courseData };
        this.url = this.courseImgUrl;
      }
    },
    onSubmit() {
      this.submitBtnLoading = true;

      this.isPublished ? this.onUpdate() : this.onCreate();
    },
    onCreate() {
      this.$store
        .dispatch("course/CREATE_COURSE", this.data)
        .then(id => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("courseHero.courseCreatedSuccessMessage")
          );
          this.$router.push(`/courses/${id}`);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    onUpdate() {
      this.$store
        .dispatch("course/UPDATE_COURSE", {
          id: this.data.id,
          formData: this.data
        })
        .then(uuid => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("courseHero.courseUpdatedSuccessMessage")
          );
          this.$router.push(`/courses/${uuid}`);
        })
        .catch(error => {
          console.log("error", error);

          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    },
    showImageUpload() {
      this.$bvModal.show("image-upload");
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    validateCategory() {
      this.$emit("clearCategoryError");
      this.categoryError = "";

      if (this.data.categories.includes(this.newCategory.trim())) {
        this.categoryError = (this as any).$t("courseHero.categorySameError");
      } else if (/\s/g.test(this.newCategory)) {
        this.categoryError = (this as any).$t("courseHero.categorySpaceError");
      }
    },
    addCategory() {
      if (
        this.newCategory.trim().length === 0 ||
        this.data.categories.length >= 8 ||
        this.categoryError
      ) {
        return;
      }

      this.data.categories.push(this.newCategory);
      this.newCategory = "";
    },
    deleteCategory(index: number) {
      this.data.categories.splice(index, 1);
    },
    onDiscard() {
      if (!this.isPublished) {
        this.$router.push(`/profile`);
      } else {
        this.$router.push(`/courses/${this.$route.params.id}`);
      }
    },
    countdown() {
      const zone = this.profile?.timezone_format || "Asia/Karachi";
      this.dateTime = moment()
        .tz(zone)
        .format("LT")
        .toString();
      setTimeout(() => this.countdown(), 1000);
    },
    setImageUrl(file: string) {
      this.url = file;
    },
    setGalleryImage(url: string) {
      this.url = url;
      this.$bvModal.hide("image-upload");
      this.$bvModal.hide("photo-gallery");
      this.$bvModal.show("clipper");
    }
  }
});
